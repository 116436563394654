.home{
    width: 100%;
    padding: 1% 5%;
    overflow: scroll;
    height: 100vh;
    .trackandep{
        display: flex;
        place-content: center;
        margin-top: 5vw;

        p{
            position: absolute;
            left: 25%;
            top: 63%;
            right: 0;
            bottom: 0;
            font-size: 28px;
            margin: auto;
            color: #fff;
        }
    
        .posttrack{
            width: 30vw;
            height: 15vw;
          border-radius: 1vw 0 0 1vw;
        }

        .symbol{
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 25%;
            margin: auto;
        }

        .postep{
            width: 30vw;
            height: 15vw;
            border-radius: 0 1vw 1vw 0;
        }

    }

    .todayfreshmusic{
        margin-top: 4vw;

        h2{
            font-size: 42px;
        }
    }

}

