// @import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');

body, html, .App, #root, .auth-wrapper {
  width: 100%;
  height: 100%;
}
* {
  box-sizing: border-box;
}
body {
  background: #fff !important;
  min-height: 100vh;
  height: max-content;
  display: flex;
  font-weight: 400;
  font-family: TelegrafRegular;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #858585;
  -webkit-text-fill-color: #858585 !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.error{
  color: #E73D3B;
  font-size: smaller;
  display: block;
}

.warningerror{
  color: #fff;
  font-size: smaller;
  display: block;
}

.apierror{
  color: #E73D3B;
  font-size: 15px;
  display: block;
  text-align: center;
}

.form-control{
  box-shadow: none !important;
}

.Toastify__toast ,.Toastify__toast--error {
  background: #fff !important;
  color: #004848;
  border: 1px solid aliceblue;
}
.Toastify__toast--error{
  background: #fff !important;
  color: #004848;
  border: 1px solid aliceblue;
}
.Toastify__toast--success{
  background: #fff !important;
  color: #004848;
  border: 1px solid aliceblue;
}
.Toastify__close-button {
  color: #004848 !important;
}
.Toastify__progress-bar {
  background: #004848 !important;

}