.kbutton {
    width: 86px;
    height: 40px;
    background-color: #f9cb11;
    border: none;
    border-radius:6px;
    position:absolute;
    top: 50%;
    left:50%;
    cursor:pointer;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.2);
    outline: 0;
    transition: all 0.25s ease;
  
    &:hover {
      opacity: 0.8;
      transition: all 0.25s ease;    
    }
  }