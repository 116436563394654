.seedjlogo{
    padding-top: 6vw;
    margin-left:9vw;
}
.login-container{
  width: 100%;
  height: 100vh;
  background: #000 !important;
.login{

    width: 22vw;
    left: 21%;
    position: relative;
    background: #000 !important;


    .welcome{
        color: #ffffff;
        font-size: 5vw;
        font-weight: 600;
    }
    .hello{
        color: #ffffff;
        font-size: 4vw;
        height: 60px;
    }

    

     label{
        color: #ffffff;
        font-size: 22px;
    }

    form{
        margin-top: 4vw;
    }

h1,h2,h3,h4,h5,h6,span {
    font-weight: 500;
    font-family: 'Fira Sans', sans-serif;
  }
  
  
  .navbar-light {
    background-color: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  }
  
  .auth-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
  }
  
  .auth-inner {
    width: 450px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all .3s;
  }
  
  .auth-wrapper .form-control:focus {
    border-color: #000 !important;
    box-shadow: none;
  }
  
  .auth-wrapper h3 {
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
  }
  
  .custom-control-label {
    font-weight: 400;
  }
  
  .forgot-password,
  .forgot-password a {
    font-size: 16px;
    padding-top: 61px;
    color: #fff;
    font-weight: 600;
    left: 90%;
    position: absolute;
    width: 200px;
  }
  
  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff !important;
    background-color:transparent !important;
    background-clip: padding-box;
    border: #000 !important;
    border-bottom: 2px solid #fff !important;
    border-radius: 0 !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    background: content-box !important;
}
.rememberme {
    color: #ffffff;
    font-size: 16px;
}
.login-btn{
    background: #008181;
    width: 5vw;
    font-size: 15px;
    float: right;
}
input{
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    background-color: coral !important;
    outline: none !important;
}


.signinwith{
    display: flex;
    font-size: 18px;
    font-weight: bold;
    color: #6e6e6e;
    margin-top: 3vw;

    p{
        padding-top: 25px;
    }

    img{
        padding-left: 20px;
    }
}



.donthaveaccount{
    display: flex;
    font-size: 21px;
    font-weight: bold;
    color: #fff;
    margin-top: 3vw;

    p{
        padding-right: 40px;
    }
    .signup-btn{
      border: 1px solid #008181;
      width: 6vw;
      height: 2vw;
      background: transparent;
      font-size: 13px;
      float: right;
      border-radius: 5px;
      display: flex;
    }
    .fas{
      margin-left: 5px;
      font-size: 18px;
      font-weight: 400;
  }
}

}
}
.btnfb{
  border: none;
  background: none;
  margin-right: 20px;
}