.royalties{
    width: 100%;
    padding: 1% 3%;
    overflow: scroll;
    height: 100vh;


    h2{
        font-size: 40px;
        color: #585858;
        font-family: 'TelegrafUltraBold';
    }


    table{
        width: 100%;
    }

    thead{
        // width: 100%;
       tr{ border-bottom: 4px solid #004A4A;
        border-top: 4px solid #004A4A;
        text-align: left;
        font-family: TelegrafUltraBold;
        letter-spacing: 0px;
        color: #004A4A;
        opacity: 1;
        height: auto;
        background:transparent;
        border-radius: none;}

        th{
            padding: 0.4vw 1vw;  
            border-right: 2px solid #004A4A;
          }
    }

    tr{
        width: 100%;
        height: 55px;
        background: #EDEDED 0% 0% no-repeat padding-box;
        border-radius: 8px;
        opacity: 1;   
        display: table;
        margin: 0.5vw 0;

        td{
            padding: 0vw 1vw;  
            border-right: 2px solid #004A4A;
          }

        .rowdata1{
            width: 15%;
            color: #004A4A;
            font-size: 16px;
            font-family: TelegrafUltraBold;
        }
        .rowdata2{
            width: 10%;
            color: #004A4A;
            font-size: 16px;
            font-family: TelegrafUltraLight;
        }
        .rowdata3{
            width: 15%;
            color: #004A4A;
            font-size: 16px;
            font-family: TelegrafUltraLight;
        }
        .rowdata4{
            width: 15%;
            color: #004A4A;
            font-size: 16px;
            font-family: TelegrafUltraLight;
        }
        .rowdata5{
            width: 15%;
            color: #004A4A;
            font-size: 16px;
            font-family: TelegrafUltraLight;
        }
        .rowdata6{
            width: 10%;
            color: #004A4A;
            font-size: 16px;
            font-family: TelegrafUltraLight;
        }
        .rowdata7{
            width: 10%;
            color: #004A4A;
            font-size: 16px;
            font-family: TelegrafUltraLight;
        }
        .rowdata8{
            color: #004A4A;
            font-size: 16px;
            font-family: TelegrafUltraLight;
            border:0 !important;
            text-align: -webkit-center;

            img{
                width: 2vw;
                border-radius: 0.5vw;
                margin: 0 10px;
            }
        }
     }

}