.partner{
    width: 100%;
    padding: 1% 3%;
    overflow: scroll;
    height: 100vh;


    .pageheading{
        margin-top: 3vw;
        display: flex;
        h2{
            font-size: 40px;
            color: #585858;
            font-family: 'TelegrafUltraBold';
        }

        .mainbuttons{
            color: #fff;
            margin-left: auto;
            font-family: TelegrafUltraBold;
            font-size: 22px;

            .create{   
                width: 8vw;
                height: auto;
                padding: 5px;
                background: #B9DDDD;
                box-shadow: 0px 4px 12px #0000001c;
                border-radius: 1vw;
                opacity: 1;
                border: 0;
                color: #004A4A;
            }
            .bulk{   
                width: 5vw;
                height: auto;
                padding: 5px;
                background: #004A4A;
                box-shadow: 0px 4px 12px #0000001c;
                border-radius: 1vw;
                opacity: 1;
                border: 0;
                color: #fff;
                margin: 1vw;
            }

        }

    }


    table{
        width: 100%;
    }

    thead{
        // width: 100%;
       tr{ border-bottom: 4px solid #004A4A;
        border-top: 4px solid #004A4A;
        text-align: left;
        font-family: TelegrafUltraBold;
        letter-spacing: 0px;
        color: #004A4A;
        opacity: 1;
        height: auto;
        background:transparent;
        border-radius: none;}

        th{
            padding: 0.4vw 1vw;  
            border-right: 2px solid #004A4A;
          }
    }

    tr{
        width: 100%;
        height: 55px;
        background: #B3E6E6 0% 0% no-repeat padding-box;
        border-radius: 8px;
        opacity: 1;   
        display: table;
        margin: 0.5vw 0;

        td{
            padding: 0vw 1vw;  
            border-right: 2px solid #004A4A;
          }

          .rowdata1{
            width: 10%;
            color: #004A4A;
            font-size: 16px;
            font-family: TelegrafUltraBold;
            border:0 !important;

            img{
                height: 3vw;
                width: 3vw;
                border-radius: 0.5vw;
                margin: 5px 10px;
            }
        }
        .rowdata2{
            width: 30%;
            color: #004A4A;
            font-size: 16px;
            font-family: TelegrafUltraBold;
        }
        .rowdata3{
            width: 30%;
            color: #004A4A;
            font-size: 16px;
            font-family: TelegrafUltraBold;
        }
        .rowdata4{
            width: 30%;
            color: #004A4A;
            font-size: 16px;
            // font-family: TelegrafUltraLight;
            border:0 !important;

        }

     }


     .edituserlist{
        background: #E5ECEC 0% 0% no-repeat padding-box;
        box-shadow: 0px 11px 21px #00000029;
        border-radius: 30px;
        opacity: 1;
        margin: 0.5vw 0;
        width: 100%;
        height: 16vw;
        padding: 2%;

        .heading{
            display: flex;
            
            p{
                font-size: 30px;
                font-family: TelegrafUltraLight;
                color: #004A4A;
            }

            .savebutton{
                width: 5vw;
                height: 2vw;
                padding: 5px;
                background: #004A4A 0% 0% no-repeat padding-box;
                border: none;
                border-radius: 40px;
                opacity: 1;
                font-size: 16px;
                font-family: TelegrafUltraBold;
                color: #fff;
                margin-left: auto;
             }
        }

     .edituserdetails{
        display: flex;


        .imageselector{
            width: 11vw;
            height: 9vw;
            background: #B9DDDD 0% 0% no-repeat padding-box;
            border: 10px dashed #008181;
            opacity: 1;
            margin-right: 1%;
            object-fit: cover;
            // .imageselector{
            //     display: flex;
            //     margin: 1%;
            //    justify-content: center;
               .custom-file-upload{
               width: 100%;
               height: 100%;
               /* background: #B9DDDD 0% 0% no-repeat padding-box; */
               border: 0px dashed #029B9B31;
               opacity: 1;
               color: #fff;
               font-family: TelegrafUltraBold;
               }
   
               p{
                   font-size: 22px;
                   color:#fff;
                   font-family: TelegrafUltraBold;
                   text-align: center;
                   width: 150px;
                   text-align: justify;
                   margin: 0 1%;
               }
   
               input[type="file"] {
                   display: none;
               }
   
               .uploadsymbol{
                   transform: translate(0%, -50%);
                   position: relative;
                   top: 50%;
               }
               .uploadedimage{
                   width: 100%;
                   height: 100%;
                   object-fit: cover;
               }
           }
        
        .edituserfields{
            // margin: 1% 0;
            width: 100%;


        .textfieldedituser{
            width: 98%;
            height: 3vw;
            background: #fff;
            box-shadow: 0px 4px 12px #0000001a;
            border-radius: 8px;
            opacity: 1;
            display: flex;
            margin: 1%;

            label{
                color: #008181;
                font-size: 25px;
                font-family: TelegrafUltraBold;
                padding: 10px 20px;
            }

            .divider{
                background: #858585;
                width: 1px;
            }

            input{
                border:none;
                outline: none;
                color: #858585;
                width: 85%;
                margin: 0 1%;
                font-size: 25px;
                font-family: TelegrafUltraLight;
            }
        }


    .flexrows{
        display: flex;
     }

   }

        }
    }

}