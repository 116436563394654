.uploadcontent{
    width: 100%;
    padding: 1% 3%;
    overflow: scroll;
    height: 100vh;
    
    .mainheading{
        font-size: 40px;
        color: #585858;
        font-family: 'TelegrafUltraBold';
    }

    .uploadcontentlist{
         margin: 4vw;
         display: flex;
         justify-content: space-around;

        .uploadcontenttiles{
            width: 30%;
            height: 6vw;
            border-radius: 1vw;
            color: #004A4A;
            background: #B3E6E6;
            font-family: 'TelegrafUltraBold';
            font-size: 2.5rem;
            text-align: center;
            padding: 10px;
            position: relative;
            box-shadow: 0px 10px 16px #00000029;

            .vertical-center {
                margin: 0;
                position: absolute;
                top: 50%;
                transform: translate(-50%,-50%);
                left: 50%;
                width: fit-content;

                img{
                    padding-right: 10px;
                }
              }


        }
        :hover{
            color: #fff;
            background: #004A4A;
          }
    }

    .uploadcontentlisthorizontal{
        margin: 4vw 2vw;

        .uploadcontenttiles2{
            width: 100%;
            height: 5vw;
            border-radius: 1vw;
            color: #004A4A;
            background: #EFE9E9;
            font-family: 'TelegrafUltraBold';
            font-size: 2.5rem;
            padding: 10px;
            position: relative;
            margin: 1vw 0;
            box-shadow: 0px 10px 16px #00000029;
            .divcenter {
                margin: auto;
                padding: 10px;
                border: 0 !important;
              }
        }

        :hover{
            border: 5px solid #029B9BE3;
        }
    }



    .selectedsection{
        .uploadcontentsection{
            width: 100%;
            // padding: 1% 3%;
            // overflow: scroll;
            // height: 100vh;
        
            .pageheading{
                margin-top: 3vw;
                display: flex;
                h2{
                    font-size: 40px;
                    color: #585858;
                    font-family: 'TelegrafUltraBold';
                    display: flex;

                    p{
                        color: #004A4A;
                        margin-right: 15px;
                        font-family: TelegrafRegular;
                        font-size: 40px;
                    }
                }
        
                .mainbuttons{
                    color: #fff;
                    margin-left: auto;
                    font-family: TelegrafUltraBold;
                    font-size: 22px;
        
                    .create{   
                        width: 8vw;
                        height: auto;
                        padding: 5px;
                        background: #B9DDDD;
                        box-shadow: 0px 4px 12px #0000001c;
                        border-radius: 1vw;
                        opacity: 1;
                        border: 0;
                        color: #004A4A;
                    }
                    .bulk{   
                        width: 5vw;
                        height: auto;
                        padding: 5px;
                        background: #004A4A;
                        box-shadow: 0px 4px 12px #0000001c;
                        border-radius: 1vw;
                        opacity: 1;
                        border: 0;
                        color: #fff;
                        margin: 1vw;
                    }
        
                }
        
            }
        
        }
    }

}