.menu {
    height: 100vh;
    background: #004A4A;
    height: 100vh;
    width: 20vw;
    opacity:0; 
    // position: absolute;
    // visibility: hidden;
    // transition: all 0.25s ease;
    // transform: translateX(-50%);

    .nav-link{
        color: #fff !important;
        text-decoration: none;
        display: block;
        padding: 19px 50px;
        font-size: 20px;
        
        .active-border{
            display: none;
        }

        img{
            width: 1.7vw;
            margin-right: 2vw;
        }
    }

    .submenu-nav-link{
      color: #fff !important;
      text-decoration: none;
      display: block;
      padding: 15px 50px;
      font-size: 16px;
      
      .active-border{
          display: none;
      }

      img{
          width: 1.5vw;
          margin-right: 2vw;
      }
    }

    .submenu-nav-link:hover{
      color: #008181 !important;
      background-color:#E5ECEC !important;
    }

    .submenu-link-active{
      color: #008181 !important;
      background-color:#E5ECEC!important;
      .active-border{
          display: block;
          width: 55% ;
          border: 1px solid #585858;
      }
  }

    .nav-link:hover{
        color: #fff !important;
        background-color:#585858 !important;
    }
    .link-active{
        color: #fff !important;
        background-color:#585858 !important;
        .active-border{
            display: block;
            width: 55% ;
            border: 1px solid #585858;
        }
    }
  
    ul {
      padding: 0;
      margin: 0;
  
      li {
        font-size: 16px;
        transition: all 0.25s ease;
        animation: fadeInRight .25s ease forwards;
        opacity:0;
        color: #fff;
        border-bottom: 1px solid #fff;
        &:hover {
          opacity: 0.8;
          transition: all 0.25s ease;
          background: #008181;
          cursor: pointer;
        }

        &:active{
            opacity: 0.8;
            transition: all 0.25s ease;
            background: #008181;
            cursor: pointer;
        }
      }
    }
  }
  
  .active {
    opacity:1;
    visibility: visible;
    transition: all 0.25s ease;
    transform: translateX(0);
  }
  
  @-webkit-keyframes fadeInRight {
    0% {
      opacity: 0;
      left: 50%;
    }
    100% {
      opacity: 1;
      left: 0;
    }
  }
  
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      left: 50%;
    }
    100% {
      opacity: 1;
      left: 0;
    }
  }