.customtable{

    width: 100%;

    thead{
        // width: 100%;
       tr{ border-bottom: 4px solid #004A4A;
        border-top: 4px solid #004A4A;
        text-align: left;
        font-family: TelegrafUltraBold;
        letter-spacing: 0px;
        color: #004A4A;
        opacity: 1;
        height: auto;
        background:transparent;
        border-radius: none;}

        th{
            padding: 0.4vw 1vw;  
            border-right: 2px solid #004A4A;
          }
    }

    tr{
        width: 100%;
        height: 75px;
        background: #004A4A1A 0% 0% no-repeat padding-box;
        border-radius: 8px;
        opacity: 1;   
        display: table;
        margin: 0.5vw 0;

        td{
            padding: 0vw 1vw;  
            border-right: 2px solid #004A4A;
          }

        .rowdata1{
            width: 20%;
            color: #004A4A;
            font-size: 16px;
            font-family: TelegrafUltraBold;

            img{
                height: 3vw;
                width: 3vw;
                border-radius: 0.5vw;
                margin: 0 10px;
            }
        }
        .rowdata2{
            width: 35%;
            color: #004A4A;
            font-size: 16px;
            font-family: TelegrafUltraBold;
        }
        .rowdata3{
            width: 30%;
            color: #004A4A;
            font-size: 16px;
            font-family: TelegrafUltraBold;
        }
        .rowdata4{
            color: #004A4A;
            font-size: 16px;
            font-family: TelegrafUltraLight;
            border:0 !important;
        }
     }
     .edituserlist{
        background: #E5ECEC 0% 0% no-repeat padding-box;
        box-shadow: 0px 11px 21px #00000029;
        border-radius: 30px;
        opacity: 1;
        margin: 0.5vw 0;
        width: 100%;
        height: 20vw;
        padding: 2%;

        .heading{
            display: flex;
            
            p{
                font-size: 40px;
                font-family: TelegrafUltraLight;
                color: #004A4A;
            }

            .savebutton{
                width: 5vw;
                height: 2vw;
                padding: 5px;
                background: #004A4A 0% 0% no-repeat padding-box;
                border: none;
                border-radius: 40px;
                opacity: 1;
                font-size: 16px;
                font-family: TelegrafUltraBold;
                color: #fff;
                margin-left: auto;
             }
        }

        .edituserdetails{
            display: flex;

            .imageselector{
                width: 12vw;
                height: 12vw;
                background: #B9DDDD 0% 0% no-repeat padding-box;
                border: 10px dashed #008181;
                opacity: 1;
            }
            
            .edituserfields{
                // margin: 1% 0;
                width: 100%;


            .textfieldedituser{
                width: 98%;
                height: 3vw;
                background: #fff;
                box-shadow: 0px 4px 12px #0000001a;
                border-radius: 8px;
                opacity: 1;
                display: flex;
                margin: 1%;

                label{
                    color: #008181;
                    font-size: 25px;
                    font-family: TelegrafUltraBold;
                    padding: 10px 20px;
                }

                .divider{
                    background: #858585;
                    width: 1px;
                }

                input{
                    border:none;
                    outline: none;
                    color: #858585;
                    width: 85%;
                    margin: 0 1%;
                    font-size: 25px;
                    font-family: TelegrafUltraLight;
                }
            }


        .flexrows{
            display: flex;
         }


         .genretags{
            display: inline-flex;
            margin: 1vw 0vw;
            width: 100%;
            border: none;
            outline: none;

            p{
                    color: #008181;
                    font-size: 20px;
                    font-family: TelegrafUltraBold;
                    padding: 10px 20px;
            }
    
            .ReactDropdownSelect {
                box-sizing: border-box;
                position: relative;
                display: flex;
                border: none !important;
                outline: none !important;
                width: 18vw;
                padding: 2px 15px;
                flex-direction: row;
                direction: ltr;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 4px 12px #0000001c;
                border-radius: 25px;
                align-items: center;
                cursor: pointer;
                min-height: 3vw;
                pointer-events: all;
    
                .react-dropdown-select-content {
                    display: flex !important;
                    border: none !important;
                    outline: none !important;
                    width: 100% !important;
                    flex-wrap: inherit !important;
                    align-items: center;
                    font-size: 24px;
                    color: #008181 !important;
                }
    
    
                .react-dropdown-select-option{
                    background-color: #008181 !important;
                    border-radius: 2px !important;
                    border: 1px solid #008181 !important;
                    color: #fff !important;
                    /* font-family: sans-serif; */
                    font-size: 13px !important;
                    font-weight: 600 !important;
                    /* margin-bottom: 5px; */
                    /* margin-right: 5px; */
                    /* padding: 3px !important; */
                    width: auto;
                    align-items: center;
                    
                  }
                .react-dropdown-select-option-label{
                    width: max-content !important;
                }
    
             .react-dropdown-select-item-selected{
                background-color: #008181 !important;
             }
            }
    
        
        .react-dropdown-select-content {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex !important;
            border: none !important;
            outline: none !important;
            border-radius: 0 0.3vw 0.3vw 0 !important;
            width: 100% !important;
            flex-wrap: inherit !important;
        }
            
        }

            }
        }

    }
}