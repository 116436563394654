.profile{
    width: 100%;
    padding: 1% 5%;
    overflow: auto;
    height: 100vh;
    background: #141414;


     .profileimgname{
        position: relative;
        top: 6%;
        text-align: -webkit-center;
        // margin-bottom: 10vw;
        .profileimg{
            height: 15vw;
            width: 15vw;
            border-radius: 8vw;
            background: #fff;
            padding: 0.2%;
        }

        h2{
            color: #fff;
            font-size: 50px;
            margin-top: 1vw;
            font-family: TelegrafUltraBold;
         }

         .profiletype{
            text-align: center;
            font-family: TelegrafUltraBold;
            font-size: 20px;
            letter-spacing: 0px;
            color: #FFFFFF;
         }

         .location{
            text-align: center;
            font-family: TelegrafUltraLight;
            font-size: 26px;
            letter-spacing: 0px;
            color: #FFFFFF;
         }

         .bio{
            text-align: center;
            font-family: TelegrafUltraLight;
            font-size: 26px;
            letter-spacing: 0px;
            color: #FFFFFF;
            width: 70%;
         }

         hr{
             background-color: #fff;
             margin-top: 4vw;
         }

     }

     .infoandmedia{
         display: flex;
     }

     .infoandmedialabel{
        display: block;
     }

     .usercomments{
        width: 70%;
        padding: 2%;

        .userowncomment{
            height: 7vw;
            border-radius: 0.6vw;
            background: #f5f5fb;
            display: flex;
            align-items: center;
            padding: 5%;
            margin: 1vw 0vw;
            
            img{
                height: 4vw;
                width: 4vw;
                border-radius: 2vw;
            }

            .sendcomment{
                background: #fff;
                margin: 1.5% 4%;
                border-radius: 0.5vw !important;
                width: 80%;
                display: flex;

                input[type="text" i] {
                    background: #fff !important;
                    outline: none !important;
                    border: 0px !important;
                    width: 85% !important;
                    height: 3vw !important;
                    border-radius: 1vw !important;
                    margin-left: 3%;
                }
                button{
                    background: #32a6a6 !important;
                    color: #fff !important;
                    height: 1.5vw !important;
                    border: 1px solid #8bc9c9 !important;
                    width: 4vw !important;
                    border-radius: 1vw !important;
                    align-self: center;
                    margin-right: 3%;
            }
        }
        }



        .userowntrackscomment{
            height: 26vw;
            border-radius: 0.6vw;
            background: #f5f5fb;
            display: block;
            align-items: center;
            margin: 1vw 0vw;

            .header{
                margin: 0.5% 0;
                width: 100%;
                display: flex;
                align-items: center;
                padding: 2% 5%;
                img{
                    height: 4vw;
                    width: 4vw;
                    border-radius: 2vw;
                }

                label{
                    color: #32a6a6 !important;
                    align-self: center;
                    margin-left: auto;
                }


            }

            .comment{
                padding: 0 5%;
                font-size: 14px;
                color: #575758;
            }

            .shareandsetting{
             display: flex;
             color: #32a6a6 !important;

             .fa-share-alt{
                margin-left: auto;
                }
             
             .fa-ellipsis-h{
                 margin-left: 10px;
                margin-right: 5%;
             }

            }


            .sendcomment{
                background: #fff;
                margin: 1.5% 0%;
                margin-left: 2%;
                border-radius: 0.5vw !important;
                width: 93%;
                display: flex;

                input[type="text" i] {
                    background: #fff !important;
                    outline: none !important;
                    border: 0px !important;
                    width: 85% !important;
                    height: 3vw !important;
                    border-radius: 1vw !important;
                    margin-left: 3%;
                }
                button{
                    background: #32a6a6 !important;
                    color: #fff !important;
                    height: 1.5vw !important;
                    border: 1px solid #8bc9c9 !important;
                    width: 4vw !important;
                    border-radius: 1vw !important;
                    align-self: center;
                    margin-right: 3%;
            }
        }
        }
     }

}