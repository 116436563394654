.createuser{

    background: #E5ECEC 0% 0% no-repeat padding-box;
    box-shadow: 0px 11px 21px #00000029;
    border-radius: 30px;
    opacity: 1;
    padding: 2%;

    .mainheading{
        font-size: 40px;
        color:#004A4A;
    }
     
    .createuserform{
        display: flex;
        margin: 2%;
    
        .imageselector{
            width: 11vw;
            height: 9vw;
            background: #B9DDDD 0% 0% no-repeat padding-box;
            border: 10px dashed #008181;
            opacity: 1;
            margin-right: 1%;
        }
    
    
    .textfieldedituser{
        width: 98%;
        height: 3vw;
        background: #fff;
        box-shadow: 0px 4px 12px #0000001a;
        border-radius: 8px;
        opacity: 1;
        display: flex;
        margin: 1%;
    
        label{
            color: #008181;
            font-size: 16px;
            font-family: TelegrafUltraBold;
            padding: 15px 15px;
            width: inherit;
            max-width: 27%;
        }
    
        .divider{
            background: #0000001A;
            width: 1px;
        }
    
        input{
            border:none;
            outline: none;
            color: #858585;
            width: 100%;
            margin: 0 1%;
            font-size: 25px;
            font-family: TelegrafUltraLight;
        }
    }
    
    
    .flexrows{
    display: flex;
    }
    
    
    .genretags{
    display: inline-flex;
    margin: 1vw 0vw;
    width: 100%;
    border: none;
    outline: none;
    
    p{
        color: #008181;
        font-size: 18px;
        font-family: TelegrafUltraBold;
        padding: 15px 20px;
    }
    
    .ReactDropdownSelect {
        box-sizing: border-box;
        position: relative;
        display: flex;
        border: none !important;
        outline: none !important;
        width: 20vw;
        padding: 2px 15px;
        flex-direction: row;
        direction: ltr;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 12px #0000001c;
        border-radius: 25px;
        align-items: center;
        cursor: pointer;
        min-height: 3vw;
        pointer-events: all;
    
        .react-dropdown-select-content {
            display: flex !important;
            border: none !important;
            outline: none !important;
            width: 100% !important;
            flex-wrap: inherit !important;
            align-items: center;
            font-size: 24px;
            color: #008181 !important;
        }
    
    
        .react-dropdown-select-option{
            background-color: #008181 !important;
            border-radius: 2px !important;
            border: 1px solid #008181 !important;
            color: #fff !important;
            /* font-family: sans-serif; */
            font-size: 13px !important;
            font-weight: 600 !important;
            /* margin-bottom: 5px; */
            /* margin-right: 5px; */
            /* padding: 3px !important; */
            width: auto;
            align-items: center;
            
          }
        .react-dropdown-select-option-label{
            width: max-content !important;
        }
    
     .react-dropdown-select-item-selected{
        background-color: #008181 !important;
     }
    }
    
    
    .react-dropdown-select-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex !important;
    border: none !important;
    outline: none !important;
    border-radius: 0 0.3vw 0.3vw 0 !important;
    width: 100% !important;
    flex-wrap: inherit !important;
    }
    
    }
    
    }

    .generateautomatically {
        font-size: 16px;
        color: #008181;
        font-family: 'TelegrafUltraBold';
        position: absolute;
        right: 7%;
    }

    .sendinvitation{
        background: #004A4A 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 12px #0000001c;
        border-radius: 0.6vw;
        opacity: 1;
        border: 0;
        color: #fff;
        width: 10vw;
        height: 3vw;
        font-family: 'TelegrafUltraBold';
        position: relative;
        left: 84%;
        margin-top: 2vw;
    }
}