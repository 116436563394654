.navbar{
  background-color: #004A4A;
  height: 7%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 1% 2%;
  align-items: center;
  font-weight: 500;
  position: relative;
  z-index: 50;
  .nav-title{
      color: #fff;
      font-size: 30px;
  }
 
  .nav-item{
      display: flex;
      list-style: none;
      width: 25%;
      box-sizing: border-box;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 500;
      margin-left: 5px;
      li .dropdown-menu{
       display: none;
      }
      li:hover .dropdown-menu{
          z-index: 1;
          display: flex;
          position: absolute;
          flex-direction: column;
          justify-content: space-evenly;
          list-style: none;
          right: 16.5%;
          left: 89%;
          top:45%;
          background-color: #ffffff;
          min-height: 154px;
          padding-left: 25px;
          padding-left: 15px;
          border-radius: 8px;
          box-shadow: 0px 0.3px 14px rgba(0,0,0,0.07);
          width: 13%;
          border: 1px solid #F1F1F1;
          .menu-link{
              display: flex;
              text-decoration: none;
              cursor: pointer;
              color: #909496;
              font-size: 16px;
              padding-top:10px;
          }
          :hover{
              color: #fff;
          }
      }
      li .dropdown-menu2{
          display: none;
      }
      li:hover .dropdown-menu2{
          z-index: 1;
          display: flex;
          flex-direction: column;
          position: absolute;
          flex-direction: column;
          list-style-type: circle;
          right: 16.5%;
          left: 73%;
          color: #008181;
          background-color: #ffffff;
          min-height: 154px;
          max-height: 20vw;
          padding-left: 25px;
          border-radius: 8px;
          box-shadow: 0px 0.3px 14px rgba(0,0,0,0.07);
          width: 20%;
          border: 1px solid #F1F1F1;
          overflow: auto;
          .bullet{
              font-size: 30px;
              margin: 1px 3px 0px 0px;
          }
          .menu-link{
              cursor: pointer;
              color: #008181;
              font-size: 14px;
              height: 5px;
          }
          .menu-link-message{
              color: darkgrey;
              font-size: 12px;
              // border-bottom: 1px solid gainsboro;
              padding: 1px;
          }
          // :hover{
          //     color: #E73D3B;
          // }
      }
      .nav-link{
          // display: block;
          height: 30px;
          text-decoration: none;
          cursor: pointer;
          color: #fff;
          font-size: 22px;
          .active-border{
              display: none;
          }
      }

      .nav-link:hover{
          color: #fff !important;
      }
      .link-active{
          color: #fff !important;
          .active-border{
              display: block;
              width: 55% ;
              border: 1px solid #008181;
          }
      }
  }
  .bellicon
  {
      width: 1.5vw;
      height: 1.5vw;
  }
}

.lang_notselected {
    font-size: 20px;
    color: #888888;
    margin: 2px;
    margin-right: 4px;
    margin-left: 4px;
    font-weight: 500;
    cursor: pointer;
}
.lang_selected {
    font-size: 20px;
    color: #fff;
    margin: 2px;
    margin-right: 4px;
    margin-left: 4px;
    font-weight: 500;
    cursor: pointer;
}
.lang_divider {
    width: 2px;
    height: 31px;
    background-color: #fff;
}