.seedjlogo{
    padding-top: 6vw;
    margin-left:9vw;
}
.forgotpassword-container{
  width: 100%;
  height: 100vh;
  background: #000 !important;
.forgotpassword{


    .ForgotPassword{
      color: #fff;
      margin-bottom: 2vw;
    }

    width: 22vw;
    left: 25%;
    position: relative;
    /* height: 100%; */
    background: #000 !important;
    top: 5%;


    .welcome{
        color: #ffffff;
        font-size: 5vw;
        font-weight: 600;
    }
    .hello{
        color: #ffffff;
        font-size: 4vw;
        height: 60px;
    }

    

     label{
        color: #ffffff;
        font-size: 22px;
    }

    .error{
        color: #E73D3B;
        font-size: smaller;
        display: block;
      }

    form{
        margin-top: 4vw;
    }

h1,h2,h3,h4,h5,h6,span {
    font-weight: 500;
  }
  
  
  .navbar-light {
    background-color: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  }
  
  .auth-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
  }
  
  .auth-inner {
    width: 450px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all .3s;
  }
  
  .auth-wrapper .form-control:focus {
    border-color: #000 !important;
    box-shadow: none;
  }
  
  .auth-wrapper h3 {
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
  }
  
  .custom-control-label {
    font-weight: 400;
  }
  
  
  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff !important;
    background-color:transparent !important;
    background-clip: padding-box;
    border: #000 !important;
    border-bottom: 2px solid #fff !important;
    border-radius: 0 !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    background: content-box !important;
}

.resetpassword-btn{
    background: #008181;
    width: 9vw;
    font-size: 15px;
    float: left;
    margin-top: 2vw;
}
input{
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    background-color: coral !important;
    outline: none !important;
}



}
}


.fa-check{
  color: aliceblue;
  background: green;
  width: 1.5vw;
  height: 1.5vw;
  border-radius: 1vw;
  padding: 0.3vw;
  text-align: center;
  margin-left: 10px;
}

.fa-times{
  color: aliceblue;
  background: red;
  width: 1.5vw;
  height: 1.5vw;
  border-radius: 1vw;
  padding: 0.3vw;
  text-align: center;
  margin-left: 10px;
}