body {
  margin: 0;
  font-family: TelegrafRegular ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }


@font-face {
  font-family: "TelegrafRegular";
  src: local("Poppins"),
    url("./assets/fonts/Telegraf/TelegrafRegular400.otf") format("truetype");
}

@font-face {
  font-family: "TelegrafUltraBold";
  src: local("Poppins"),
    url("./assets/fonts/Telegraf/TelegrafUltraBold800.otf") format("truetype");
}

@font-face {
  font-family: "TelegrafUltraLight";
  src: local("Poppins"),
    url("./assets/fonts/Telegraf/TelegrafUltraLight200.otf") format("truetype");
}