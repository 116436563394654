.postTrackEp{

    width: 100%;
    padding: 1% 5%;
    overflow: scroll;
    height: 100vh;

    .mainheading{
        color: #000;
        font-weight: 900;
        font-size: 36px;
        .fas{
           margin-right: 10px;
        }
    }

    .uploadtrackorep{
        margin: 1vw 0vw;
        display: inline-flex;
        width: 90%;
        margin-left: 5%;
        .selecteduploadbox{
            border: 10px solid #008181 ;
            border-style: dashed;
            width: 60%;
            height: 18vw;
            background: #c6e4e9;
            position: relative;
            margin: 0 0.5%;

            span{
                position: absolute;
                left: 30%;
                top: 30%;
                text-align: center;
                font-size: 30px;
                color: #8bc9cc ;
            }

           .uploadeptrack{
            position: absolute;
            left: 36%;
            top: 37%;
            font-size: 20px;
            color: #fff !important;
                .custom-file-upload {
                    border: 3px solid #fff !important;
                    color: #fff !important;
                    border-radius: 0.3vw !important;
                    display: inline-block;
                    padding: 0px 30px;
                    cursor: pointer;
                  

                     .fas{
                         margin-right: 1vw;
                     }

                 }
            input[type="file"] {
                display: none;
            }
            p{
                font-size: 17px !important;
                color: #fff;

                :hover{
                    background: transparent !important;
                }
            }

            :hover{
                background:#008181;
            }
        }
        }

        .selecteduploadboxEP{
            // border: 10px solid #008181 ;
            // border-style: dashed;
            width: 60%;
            height: 18vw;
            background: #c6e4e9;
            position: relative;
            margin: 0 0.5%;

        .uploadeptrackEP{
            font-size: 20px;
            color: #fff !important;
            display: flex;
            position: initial;

            .epblocks{
                border: 10px solid #008181;
                border-style: dashed;
                width: 100%;
                height: 9vw;
                background: #c6e4e9;
                position: relative;
                text-align: center;
                padding-top: 14%;
            
                .custom-file-upload {
                    border: 3px solid #fff !important;
                    color: #fff !important;
                    border-radius: 0.3vw !important;
                    display: inline-block;
                    padding: 0px 30px;
                    cursor: pointer;
                  

                     .fas{
                         margin-right: 1vw;
                     }

                 }
            input[type="file"] {
                display: none;
            }
            .dragndropep{
                font-size: 17px !important;
                color: #fff;
                margin: 0;
                // :hover{
                //     background: transparent !important;
                // }
            }

            .epno{
                font-size: 28px;
                font-family: TeleGrafUltraBold;
                color: #008181;
                text-align: end;
                margin-right: 8%;
            }


        }


        }


        }

        .unselecteduploadbox{
            border: 10px solid #ceced0 ;
            border-style: dashed;
            width: 40%;
            height: 18vw;
            background: #e8e8ed;
            position: relative;
            margin: 0 0.5%;

            span{
                position: absolute;
                left: 30%;
                top: 30%;
                text-align: center;
                font-size: 30px;
                color: #8bc9cc ;
            }
        }


    }



    .trackepprogress{
        
        margin: 1vw 0vw;

        .stroke{
            border: 2px solid #c6c6c6;
            border-radius: 10px;
            height: 1vw;
            width: 100%;
        }
        .rc-progress-line-path{
            stroke-linecap: square !important;
        }
    }

    .eptrackscontainer{
        border-left: 2px solid #029B9B67 ;
    }


    .tracktitleep {
        display: inline-flex;
        margin: 1vw 0vw 1vw 5vw;
        width: 97%;

        span{
            display: inline-flex;
            height: 3vw;
            width:   10%;
            border-radius: 0.3vw 0 0 0.3vw;
            background: #fff;
            color: #008181 ;
            font-size: 24px;
            align-items: center;
            padding: 1%;
            border-right: 1px solid #c6c6c6;
            font-weight: bold;
            p{
                padding: 10%;
                margin: 0;
            }
            .far{
                color: #000;
            }
          }

        input{
            height: 3vw;
            width:   85%;
            border-radius: 0 0.3vw 0.3vw 0;
            border: none;
            outline: none;
            padding: 1%;
        }
    }

    .tracktitle{
        display: inline-flex;
        margin: 1vw 0vw;
        width:   100%;

        span{
            display: inline-flex;
            height: 3vw;
            width:   10%;
            border-radius: 0.3vw 0 0 0.3vw;
            background: #fff;
            color: #008181 ;
            font-size: 24px;
            align-items: center;
            padding: 1%;
            border-right: 1px solid #c6c6c6;
            font-weight: bold;
            p{
                padding: 10%;
                margin: 0;
            }
            .far{
                color: #000;
            }
          }

        input{
            height: 3vw;
            width:   100%;
            border-radius: 0 0.3vw 0.3vw 0;
            border: none;
            outline: none;
            padding: 1%;
        }
    }



    .eptrackstitle{
        display: inline-flex;
        color: #008181;
        font-size: 23px;
        font-family: telegrafUltraBold;
        width: 100%;
        height: 0;
        .detail{
            margin-left: 10px;
            font-family: telegrafUltraLight;
        }
        .dashline{
            color:#029B9B67;
            font-size: 25px;
            margin-right: 10px;
        }
      }

    .genretags{
        display: inline-flex;
        margin: 1vw 0vw;
        width:   42%;
        border: none;
        outline: none;

        .genreimg{
            display: inline-flex;
            height: 3vw;
            width: 17%;
            border-radius: 0.3vw 0 0 0.3vw;
            color: #008181;
            font-size: 22px;
            align-items: center;
            padding: 1%;
            font-weight: bold;
            p{
                padding: 10%;
                margin: 0;
            }
            .far{
                color: #000;
            }
          }

          .ReactDropdownSelect {
            box-sizing: border-box;
            position: relative;
            display: flex;
            border: none !important;
            outline: none !important;
            width: 15vw;
            padding: 2px 15px;
            flex-direction: row;
            direction: ltr;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 4px 12px #0000001c;
            border-radius: 25px;
            align-items: center;
            cursor: pointer;
            min-height: 36px;
            pointer-events: all;

            .react-dropdown-select-content {
                display: flex !important;
                border: none !important;
                outline: none !important;
                width: 100% !important;
                flex-wrap: inherit !important;
                align-items: center;
                font-size: 22px;
                color: #008181 !important;
            }


            .react-dropdown-select-option{
                background-color: #008181 !important;
                border-radius: 2px !important;
                border: 1px solid #008181 !important;
                color: #fff !important;
                /* font-family: sans-serif; */
                font-size: 13px !important;
                font-weight: 600 !important;
                /* margin-bottom: 5px; */
                /* margin-right: 5px; */
                /* padding: 3px !important; */
                width: auto;
                align-items: center;
                
              }
            .react-dropdown-select-option-label{
                width: max-content !important;
            }

         .react-dropdown-select-item-selected{
            background-color: #008181 !important;
         }
        }

    input{
        height: 3vw;
        // border-radius: 0 0.3vw 0.3vw 0;
        border: none;
        outline: none;
        padding: 1%;
        // width: 100%;
    }
    
    .react-dropdown-select-content {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex !important;
        border: none !important;
        outline: none !important;
        border-radius: 0 0.3vw 0.3vw 0 !important;
        width: 100% !important;
        flex-wrap: inherit !important;
    }
        
    }


    .subgenretags{
        display: inline-flex;
        margin: 1vw 0vw;
        width:   58%;
        background: #fff;
        border: none;
        outline: none;

        .genreimg{
            display: inline-flex;
            height: 3vw;
            width: 15%;
            border-radius: 0.3vw 0 0 0.3vw;
            background: #fff;
            color: #008181;
            font-size: 22px;
            align-items: center;
            padding: 1%;
            border-right: 1px solid #c6c6c6;
            font-weight: bold;
            font-family: telegrafUltraBold;
            p{
                padding: 10%;
                margin: 0;
            }
            .far{
                color: #000;
            }
          }

          .ReactDropdownSelect {
            box-sizing: border-box;
            position: relative;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            border: none !important;
            outline: none !important;
            border-radius: 0 0.3vw 0.3vw 0 !important;
            width: 100%;
            padding: 2px 5px;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            direction: ltr;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            cursor: pointer;
            min-height: 36px;
            pointer-events: all;


            .react-dropdown-select-option{
                background-color: #008181 !important;
                border-radius: 2px !important;
                border: 1px solid #008181 !important;
                color: #fff !important;
                // display: inline-block;
                /* font-family: sans-serif; */
                font-size: 15px !important;
                font-weight: 600 !important;
                margin-bottom: 5px;
                margin-right: 5px;
                padding: 3px !important;
                width: auto;
                height: 2vw;            }

         .react-dropdown-select-item-selected{
            background-color: #008181 !important;
         }
        }

    input{
        height: 3vw;
        // border-radius: 0 0.3vw 0.3vw 0;
        border: none;
        outline: none;
        padding: 1%;
        // width: 100%;
    }
    
    .react-dropdown-select-content {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex !important;
        border: none !important;
        outline: none !important;
        border-radius: 0 0.3vw 0.3vw 0 !important;
        width: 100% !important;
        flex-wrap: inherit !important;
    }
        
    }


    .previewimage{
        margin: 1vw 0vw;

        h3{
            color: #008181;
            font-weight: 900;
            font-size: 32px;
            height: 30px;
            font-family: telegrafUltraBold;

            img{
                margin-left: 15px;
            }
        }

        p{
            color: #525252;
            font-size: 27px;
            font-family: telegrafUltraLight;
        }

        .uploadtrackimage{
            display: flex;
          
            .uploadbox{
                border: 8px solid #008181 ;
                border-style: dashed;
                width: 50%;
                height: 14vw;
                background: #c6e4e9;
                position: relative;
                cursor: pointer;
               

                    img{
                        color: #008181;
                        font-size: 45px;
                        font-weight: 200;
                        position: absolute;
                        top: 37%;
                        left: 45%;
                    }
    
                .uploadcoverimagevalid{
                        color: #008181;
                        font-size: 14px;
                        font-family: 'TelegrafUltraBold';
                        text-align: center;
                        margin-top: 25%;
                    }

                input[type="file"] {
                    display: none;
                    height: 12vw;
                }
            }

            .previewimg{
                width: 50%;
                padding: 0 6%;
                p{
                      color: #008181;
                      font-size: 20px;
                      font-weight: 800;
                }


                img{
                    height: 11vw;
                    width: 30vw;
                    border-radius: 0.3vw;
                }
            }
        }
    }

    .postonyourprofile{
        margin: 1vw 0vw;

        h3{
            color: #008181;
            font-weight: 900;
            font-size: 32px;
            height: 30px;

        }

        p{
            color: #525252;
            font-size: 27px;
        }

        .additionaldescription{

            height: 14vw;
            background: #fff;
            border-radius: 0.3vw;
            padding: 2%;
            border: 3px solid #008181;
            p{
                color: #008181;
                font-weight: 900;
                font-size: 22px;
                font-family: telegrafUltraBold;
                .fas{
                    margin-right: 15px;
                 }
            }

            textarea{
                height: 8vw;
                background: #fff;
                padding: 1%;
                width: 100%;
                outline: none;
                border: none;
            }
        }
    }


    .monitoring{
        margin: 1vw 0vw;
        display: flex;
        h3{
            color: #008181;
            font-weight: 900;
            font-size: 32px;
            height: 30px;

        }

        p{
            color: #525252;
            font-size: 27px;
            font-family: telegrafUltraLight;
        }


        .monitoringfield{
            margin-left: auto;
            display: flex;
            position: relative;
            .customlabels {
                height: 2.5vw;
                display: block;
                border-radius: 0.5vw;
                width: 2.5vw;
                border: 2px solid #008181;
                color: #008181;
                font-size: 30px;
                text-align: center;
                margin: 0 4px;
            }

            .customlabelimage{
                height: 2.5vw;
                display: block;
                border-radius: 0.5vw;
                width: 2.5vw;
                border: 3px solid #008181;
                margin: 0 4px;
            }

            .dropdown {
                position: relative;
                display: inline-block;
              }

              .show{
                  display: block !important;
              }
              
              .dropdown-content {
                display: none;
                position: absolute;
                background-color: #f6f6f6;
                height: 10vw;
                overflow: auto;
                padding: 10px;
                bottom: 6vw;
                right: 0vw;
                border: none;
                background: #E4F7F7 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 20px;
                opacity: 1;
                z-index: 1;

                ::placeholder{
                    color: #008181 !important;
                  }

                input{
                    text-align: left;
                    font: normal normal 800 22px/27px Telegraf;
                    letter-spacing: 0px;
                    color: #008181;
                    opacity: 1;
                    border: 0 !important;
                    background: transparent;
                    border-bottom: 2px solid #008181 !important;
                    user-select: none !important;
                    outline: none;
                }
              }
              
              .dropdown-content p {
                color: #000;
                padding: 12px 16px;
                text-decoration: none;
                display: block;
                background-color: #e8e8ed;
                font-family: TelegrafUltraBold;
                font-size: 14px;
                margin: 2px;
              }

              .dropdown-content p:hover {
                  background-color: #008181;
                  color: #fff;
                }
                            
        }

    }

    .trackhrline {
        margin: 1vw 0vw;
        background: #008181 !important;
        height: 5px;
  }

  .postTrackbutton{
    background: #008181;
    border: none;
    color: #fff;
    font-size: 22px;
    height: 2.5vw;
    width: 6vw;
    float: right;
    border-radius: 0.2vw;
}


.tracksuccesfull{
    text-align: center;
    margin-top: 15%;

    h2{
        font-family: telegrafUltraBold;
        font-size: 3vw;
        color: #525252;
    }

    p{
        font-family: telegrafUltraLight;
        font-size: 2vw;
        color: #525252;
    }
    .buttons{
        display: flex;
        justify-content: center;       
        .button1{
            background: #008181;
            color: #fff;
            border-radius: 0.5vw;
            width: auto;
            padding: 10px;
            height: 3vw;
            margin-right: 5px;
            width: 12vw;
            border: 0;
            font-family: 'TelegrafUltraBold';
        }
        .button2{
            background: #fff;
            color: #008181;
            border-radius: 0.5vw;
            width: auto;
            padding: 10px;
            height: 3vw;
            margin-left: 5px;
            width: 12vw;
            border: 0;
            font-family: 'TelegrafUltraBold';
        }
        p{
            font-family: telegrafUltraLight;
            font-size: 1vw !important;
            color: #525252;
        }
    }
 }

}


